import React, { useContext } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { redirectToAppStore } from '../../utils/appStore';

import PublicRoute from './publicRoute';
import PrivateRoute from './privateRoute';
import RedirectToView from './redirectToView';
import RedirectToParamUrl from './redirectToParamUrl';
import RedirectToInternalUrl from './redirectToInternalUrl';

import {
  AppConfigContext,
  IMenuItemBadgeTextInfo,
} from '../../contexts/appConfigContext';
import { AppContext } from '../../contexts/appContext';
import { IScreen } from '../../views/screen/screen';

import environment from '../../utils/environment';
import useHttpCall from '../../hooks/useHttpCall';
import useSnowplowTracker from '../../hooks/useSnowplowTracker';
import useAdjustTracker from '../../hooks/useAdjustTracker';
import useLocalScreen from '../../hooks/useLocalScreen';
import currentPartner from '../../constants/partners';

// All the "manual" routes, meaning regular navigation through the browser address bar,
// native browser redirects (such as navigating navigating through the app menu)
// will result in a stateless new location, without a View object.
//
// All the programmatic navigation using the Navigation hook, such as flex navigation,
// navigating between VL screens etc, explicitly provide a View object to the location state.
//
// Each of the two route types check whether the current location has a view object in the browser location state.
// If it has, pass it to the View component where it will be rendered, otherwise, generate a new INITIAL view object
// using the Route's render function.

const Routes = () => {
  const { flexStartCall, universalLinks } = useContext(AppConfigContext);
  const { appMenuItems, updateMenuItemBadge } = useContext(AppContext);

  const location = useLocation();
  const localScreen = useLocalScreen();

  const snowplowTracker = useSnowplowTracker();
  const adjustTracker = useAdjustTracker();

  const handleHttpCall = useHttpCall();

  // NOTE: As with any router, the order of some of these routes is important.
  return (
    <Switch>
      {/* A catch-all route conditionally rendered if we have a flexStartCall,
      which should take precedence over anything else, with or without an existing session. */}
      {flexStartCall && (
        <PublicRoute
          path='/'
          render={() => (
            <RedirectToView
              view={{
                type: 'FLEX',
                source: {
                  call: flexStartCall,
                },
              }}
            />
          )}
        />
      )}

      {/* The market specific login method route. */}
      <PublicRoute
        path='/login'
        render={() => (
          <RedirectToView
            view={{
              type: 'LOGIN',
              constrainHeight: 800,
              autoHeight: !!currentPartner,
            }}
          />
        )}
      />

      {/* The sso login route. */}
      <PublicRoute
        path='/sso'
        render={() => (
          <RedirectToView
            view={{
              type: 'LOGIN',
              hideAppMenu: true,
              source: {
                loginMethod: 'SSO_LOGIN',
                ssoLoginDeeplinkParams: location.search,
              },
            }}
          />
        )}
      />

      {/* The Livi Practice route. */}
      <PublicRoute
        exact
        path='/livi-practice'
        render={() => (
          <RedirectToView
            view={{
              type: 'SCREEN',
              hideAppMenu: true,
              source: {
                url: `/api/view/livi-practice/start${location.search}`,
              },
            }}
          />
        )}
      />

      {/* The IT SaaS route. For next market please use flexStartCall from the view-layer */}
      <PublicRoute
        exact
        path='/it-web'
        render={() => (
          <RedirectToView
            view={{
              type: 'SCREEN',
              hideAppMenu: true,
              source: {
                url: `/api/view/it-web/start${location.search}`,
              },
            }}
          />
        )}
      />

      {/* The local debug route.
        Only available when running the client locally or on test environments.  */}
      {!environment.IS_PROD && (
        <PublicRoute
          exact
          path='/debug'
          render={() => (
            <RedirectToView
              view={{
                type: 'DEBUG',
                title: 'Debug',
              }}
            />
          )}
        />
      )}

      {/* Dynamically created app menu routes, equivalent of non-bundled tabs in the native apps. */}
      {appMenuItems?.map((item, i) => {
        // these are the links in the sidebar (home and health)
        return item.flexNode ? (
          <PrivateRoute
            key={i}
            path={item.path}
            render={() => {
              // Handle potential snowplow events.
              if (item.snowplowEvent) {
                snowplowTracker.trackEvent(item.snowplowEvent);
              }

              // Call the onRouteVisited endpoint if provided.
              // Used to potentially update or clear the menu item badge.
              if (item.badge?.onRouteVisitedEndpoint) {
                handleHttpCall<IMenuItemBadgeTextInfo>(
                  item.badge?.onRouteVisitedEndpoint,
                  { silent: true, persistent: true }
                )
                  .then((textInfo) => {
                    updateMenuItemBadge(item.id, textInfo);
                  })
                  .catch(() => {
                    // Fail silently.
                  });
              }

              return (
                <RedirectToView
                  view={{
                    type: 'FLEX',
                    source: { node: item.flexNode },
                    isTabRoot: true,
                  }}
                />
              );
            }}
          />
        ) : null;
      })}

      {/* The dynamic universal links */}
      {universalLinks &&
        Object.keys(universalLinks.links).map((key, i) => {
          const linkConfig = universalLinks.links[key];
          const paths = universalLinks.rootPaths.map(
            (rootPath) => `${rootPath}${key}`
          );

          const getRouteComponent = () => {
            // Handle potential tracking events.
            if (linkConfig.snowplowEvent) {
              snowplowTracker.trackEvent(linkConfig.snowplowEvent);
            }
            if (linkConfig.adjustEvent) {
              adjustTracker.trackEvent(linkConfig.adjustEvent);
            }

            // Return either a new view, in case of flex and screen routes,
            // or handle the internal schema urls.
            switch (linkConfig.type) {
              case 'FLEX':
                return (
                  <RedirectToView
                    view={{
                      type: 'FLEX',
                      source: {
                        call: {
                          ...linkConfig.flexConfig,
                          url: `${linkConfig.flexConfig.url}${location.search}`,
                        },
                      },
                    }}
                  />
                );
              case 'SCREEN':
                return (
                  <RedirectToView
                    view={{
                      type: 'SCREEN',
                      source: {
                        url: `${linkConfig.httpCall.url}${location.search}`,
                        requestOptions: {
                          method: linkConfig.httpCall.method,
                          body:
                            linkConfig.httpCall.body &&
                            decamelizeKeys(linkConfig.httpCall.body),
                        },
                      },
                    }}
                  />
                );
              case 'INTERNAL':
                return (
                  <RedirectToInternalUrl
                    url={`kry://${linkConfig.urlAction}`}
                  />
                );
            }
          };

          // The link destination might or might not require a session.
          // Render a PrivateRoute or a PublicRoute accordingly.
          return linkConfig.requiresSession ? (
            <PrivateRoute
              key={i}
              exact
              path={paths}
              render={getRouteComponent}
            />
          ) : (
            <PublicRoute
              key={i}
              exact
              path={paths}
              render={getRouteComponent}
            />
          );
        })}

      {/* Native app-only universal links. This route should only be hit if the native app is not installed.
      On iOS and Android, we'll attempt to redirect to AppStore/Google Play,
      while on desktop or any other types of devices, we'll show a local screen with download links. */}
      <PublicRoute
        path='/app-link/app'
        render={() => {
          redirectToAppStore(`${location.pathname}${location.search}`);

          return (
            <RedirectToView
              view={{
                type: 'SCREEN',
                source: {
                  screen: localScreen.notSupportedScreen,
                },
              }}
            />
          );
        }}
      />

      {/* Deeplink - /book */}
      <PrivateRoute
        path='/book'
        render={() => (
          <RedirectToView
            view={{
              type: 'FLEX',
              source: {
                call: {
                  url: `/api/view/flex-booking/start${location.search}`,
                  method: 'POST',
                },
              },
            }}
          />
        )}
      />

      {/* Deeplink - /list */}
      <PrivateRoute
        path='/list'
        render={() => (
          <RedirectToView
            view={{
              type: 'FLEX',
              source: {
                call: {
                  url: `/api/view/se-listing/registration/start${location.search}`,
                  method: 'POST',
                },
              },
            }}
          />
        )}
      />

      {/* Deeplink - /vaccination */}
      <PrivateRoute
        path='/vaccination'
        render={() => (
          <RedirectToView
            view={{
              type: 'FLEX',
              source: {
                call: {
                  url: `/api/view/flex/vaccination-se/flu/promo/start${location.search}`,
                  method: 'POST',
                },
              },
            }}
          />
        )}
      />

      {/* Deeplink - /vaccination-card */}
      <PrivateRoute
        path='/vaccination-card'
        render={() => (
          <RedirectToView
            view={{
              type: 'FLEX',
              source: {
                call: {
                  url: `/api/health-profile-view-layer/vaccinations/start${location.search}`,
                  method: 'POST',
                },
              },
            }}
          />
        )}
      />

      {/* Deeplink - /symptom-checker */}
      <PrivateRoute
        path='/symptom-checker'
        render={() => (
          <RedirectToView
            view={{
              type: 'FLEX',
              source: {
                call: {
                  url: `/api/view/symptom-checker/start${location.search}`,
                  method: 'POST',
                },
              },
            }}
          />
        )}
      />

      {/* Deeplink - /health-check */}
      <PrivateRoute
        exact
        path='/health-check'
        render={() => (
          <RedirectToView
            view={{
              type: 'FLEX',
              source: {
                call: {
                  url: '/api/health-profile-view-layer/health/sf36/promoStart',
                  method: 'POST',
                },
              },
            }}
          />
        )}
      />

      {/* Deeplink - /pollen */}
      <PrivateRoute
        exact
        path='/pollen'
        render={() => (
          <RedirectToView
            view={{
              type: 'SCREEN',
              source: { url: '/api/app/widget/pollen' },
            }}
          />
        )}
      />

      {/* Deeplink - /pregnancy-companion */}
      <PrivateRoute
        path='/pregnancy-companion'
        render={() => (
          <RedirectToView
            view={{
              type: 'FLEX',
              source: {
                call: {
                  url: '/api/health-profile-view-layer/flex/pregnancy/start',
                  method: 'POST',
                },
              },
            }}
          />
        )}
      />

      {/* Deeplink - /language-doctors */}
      <PrivateRoute
        path='/language-doctors'
        render={() => (
          <RedirectToView
            view={{
              type: 'SCREEN',
              isSpringDetails: true,
              source: {
                url: '/api/view/languagedoctors',
              },
            }}
          />
        )}
      />

      {/* Deeplink - /waiting-room */}
      <PrivateRoute
        path='/waiting-room'
        render={() => (
          <RedirectToView
            view={{
              type: 'FLEX',
              source: {
                call: {
                  url: '/api/view/meeting/room/open',
                  method: 'POST',
                  body: {},
                },
              },
            }}
          />
        )}
      />

      {/* A dev-only test route for quickly rendering a test screen based on external json.
      Just throw the json in the public folder and name it "test.json" */}
      {environment.IS_DEV && (
        <PublicRoute
          path='/test-json'
          render={() => (
            <RedirectToView
              view={{
                type: 'SCREEN',
                source: {
                  url: '/test.json',
                },
              }}
            />
          )}
        />
      )}

      {/* An experimental route used for development purposes and screenshot testing.
        Accepts an encoded json screen object provided as a url hash.
        Only available when running the client locally or on test environments. */}
      {!environment.IS_PROD && (
        <PublicRoute
          path='/screen-preview'
          render={() => {
            let screen: IScreen;

            try {
              screen = camelizeKeys(
                JSON.parse(decodeURIComponent(location.hash.substring(1))),
                (key, convert) =>
                  /^(?=.*?[A-Z])|(?=.*?[0-9])|(?=.*?[-])/.test(key)
                    ? key
                    : convert(key)
              ) as object as IScreen;
            } catch {
              screen = localScreen.errorScreen;
            }

            return (
              <RedirectToView
                view={{
                  type: 'SCREEN',
                  source: {
                    screen,
                    sourceUpdatedAt: Date.now(),
                  },
                }}
              />
            );
          }}
        />
      )}

      {/* The root route. If there is a "redirect" url parameter, that means the app was started
        width a deeplink. RedirectToParam will handle that redirect, else redirect to /home. */}
      <PrivateRoute path='/' render={() => <RedirectToParamUrl />} />
    </Switch>
  );
};

export default Routes;
