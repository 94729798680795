/** @jsx jsx */
import { CSSObject, jsx } from '@emotion/core';
import React, {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from 'react';
import { AppConfigContext, IMenuItemBadgeTextInfo } from './appConfigContext';
import { appContextReducer } from '../reducers/appContextReducer';
import request, {
  setClientLanguageHeader,
  setDeviceIdHeader,
  setSubdomainRouteMap,
  setLogoutInterceptorCallback,
  cancelAllRequests,
} from '../utils/request';
import useNavigation from '../hooks/useNavigation';
import { removeSessionCookie, setSessionCookie } from '../utils/sessionCookie';
import { IRemoteImage } from '../components/RemoteImage';
import { IFlexNode } from '../views/flex/flexNode';
import useAdjustTracker from '../hooks/useAdjustTracker';
import useSnowplowTracker, {
  IStructuredSnowplowEvent,
} from '../hooks/useSnowplowTracker';
import useHttpCall, { IHttpCall } from '../hooks/useHttpCall';
import useOverlay from '../hooks/useOverlay';
import useLanguage from '../hooks/useLanguage';
import useIdleDetector from '../hooks/useIdleDetector';
import useTheme from '../hooks/useTheme';
import useWsPushNotification from '../hooks/useWsPushNotification';
import { enableRemoteLogging } from '../utils/remoteLogger';
import environment from '../utils/environment';

let heartbeatTimeout: number;

export interface IAppContextState {
  userLoggedIn: boolean;
  idleDetectionEnabled: boolean;

  appMenuItems?: Array<{
    id: string;
    title: string;
    path: string;
    icon: IRemoteImage;
    selectedIcon: IRemoteImage;
    snowplowEvent?: IStructuredSnowplowEvent;

    flexNode?: IFlexNode;

    badge?: {
      textInfo?: IMenuItemBadgeTextInfo;
      onRouteVisitedEndpoint?: IHttpCall;
      resolvedText?: string;
    };
  }>;
}

interface IAppContext extends IAppContextState {
  updateMenuItemBadge: (id: string, textInfo?: IMenuItemBadgeTextInfo) => void;
  enableIdleDetection: (enable: boolean) => void;
  reinitializeApp: () => void;
  saveSession: (
    sessionId: string,
    deviceId: string,
    reinitialize?: boolean
  ) => void;
  logout: (showConfirmation?: boolean) => void;
}

export const AppContext = createContext({} as IAppContext);

const initialState: IAppContextState = {
  userLoggedIn: false,
  idleDetectionEnabled: true,
};

interface IAppContextProvider {
  children: ReactNode;
}

export const AppContextProvider = ({ children }: IAppContextProvider) => {
  const [state, dispatch] = useReducer(appContextReducer, initialState);

  const {
    status,
    initialLaunch,
    userId,
    appConfig,
    selectedLanguage,
    inferredLanguageKey,
    authorizedContent,
    startupMessage,
    refreshAppStartData,
  } = useContext(AppConfigContext);

  const updateBadgeNotification = useWsPushNotification('update_tab_badge');
  const navigation = useNavigation();
  const overlay = useOverlay();
  const language = useLanguage();
  const { color, font } = useTheme();

  const { userIsIdle } = useIdleDetector({
    enabled: appConfig.idleTimerEnabled,
    timeToIdle: appConfig.idleTime,
  });

  const adjustTracker = useAdjustTracker();
  const snowplowTracker = useSnowplowTracker();

  const handleHttpCall = useHttpCall();

  // Update the dynamic menu item badge using the provided value
  // or fetch the deferred value using the item-specific endpoint.
  const updateMenuItemBadge = useCallback(
    (id: string, textInfo?: IMenuItemBadgeTextInfo) => {
      const dispatchUpdate = (itemId: string, text?: string) => {
        dispatch({
          type: 'SET_MENU_ITEM_BADGE_TEXT',
          payload: {
            id: itemId,
            text,
          },
        });
      };

      switch (textInfo?.type) {
        case 'deferred':
          handleHttpCall<IMenuItemBadgeTextInfo>(textInfo.endpoint, {
            persistent: true,
            silent: true,
          })
            .then((res) => {
              updateMenuItemBadge(id, res);
            })
            .catch(() => {
              // Fail silently.
            });
          break;

        case 'text':
          dispatchUpdate(id, textInfo.value);
          break;
      }
    },
    [handleHttpCall]
  );

  const enableIdleDetection = useCallback((enableDetection: boolean) => {
    dispatch({
      type: 'SET_IDLE_DETECTION_ENABLED',
      payload: enableDetection,
    });
  }, []);

  const reinitializeApp = useCallback(() => {
    refreshAppStartData();
    navigation.redirectToPath('/', true);
  }, [navigation, refreshAppStartData]);

  const saveSession = useCallback(
    (sessionId: string, deviceId: string, reinitialize?: boolean) => {
      setSessionCookie(sessionId);
      setDeviceIdHeader(deviceId);

      // Optionally reinit the app.
      if (reinitialize) {
        reinitializeApp();
      }
    },
    [reinitializeApp]
  );

  const logout = useCallback(
    (showConfirmation?: boolean) => {
      if (!userId) {
        return;
      }

      const performLogout = () => {
        cancelAllRequests();

        request('/api/user/logout')
          .finally(() => {
            removeSessionCookie();
            reinitializeApp();
          })
          .catch(() => {
            // Fail silently.
          });
      };

      if (showConfirmation) {
        overlay.presentAlert({
          title: language.get('logout_confirmation'),
          dismissible: true,
          items: [
            {
              title: language.get('cancel'),
              onClick: () => {},
            },
            {
              title: language.get('logout'),
              isDestructive: true,
              onClick: performLogout,
            },
          ],
        });

        return;
      }

      performLogout();
    },
    [userId, language, overlay, reinitializeApp]
  );

  // Set the initial app state.
  useEffect(() => {
    dispatch({
      type: 'SET_APP_STATE',
      payload: {
        userId,
        authorizedContent,
      },
    });
  }, [userId, authorizedContent]);

  // Update client language request headers and the html document language attribute.
  useEffect(() => {
    const currentLanguage = selectedLanguage || inferredLanguageKey;

    setClientLanguageHeader(currentLanguage);
    document.documentElement.lang = currentLanguage;
  }, [inferredLanguageKey, selectedLanguage]);

  // Update the subdomain route map.
  useEffect(() => {
    setSubdomainRouteMap(appConfig.subdomainRouteMap);
  }, [appConfig]);

  // Set the logout interceptor callback.
  useEffect(() => {
    setLogoutInterceptorCallback(logout);
  }, [logout]);

  // Set dynamic menu item badges.
  useEffect(() => {
    authorizedContent?.tabSpecification.tabs.forEach((tab) => {
      updateMenuItemBadge(tab.id, tab.badge?.textInfo);
    });
  }, [authorizedContent, updateMenuItemBadge]);

  // Start or stop the periodical heartbeat depending on the login state.
  useEffect(() => {
    if (userId) {
      // Start heartbeat after stopping a potential previous one,
      // as the app might have been reinitialized.
      clearInterval(heartbeatTimeout);
      heartbeatTimeout = window.setInterval(() => {
        request('/api/user/heartbeat', {
          method: 'PUT',
          silent: true,
          persistent: true,
        });
      }, appConfig.pollInterval * 1000);
    } else {
      // Stop heartbeat.
      clearInterval(heartbeatTimeout);
    }

    return () => {
      clearInterval(heartbeatTimeout);
    };
  }, [userId, appConfig, reinitializeApp]);

  // Set the Adjust tracker state, depending on appConfig.adjustState.
  // This can change during runtime as users can choose to opt-out of tracking.
  // Events collected while Adjust is in offline mode will be sent once/if switched back to online.
  useEffect(() => {
    switch (appConfig.adjustState) {
      case 'enabled':
        adjustTracker.enableTracking();
        adjustTracker.disableOfflineMode();
        break;
      case 'disabled':
        adjustTracker.disableTracking();
        break;
      case 'offline_mode':
        adjustTracker.enableOfflineMode();
        adjustTracker.enableTracking();
        break;
    }
  }, [appConfig.adjustState, adjustTracker]);

  // Set the Snowplow tracker state, depending on appConfig.snowplowEnabled.
  // This can change during runtime as users can choose to opt-out of tracking.
  useEffect(() => {
    if (appConfig.snowplowEnabled) {
      snowplowTracker.enableTracking();
    } else {
      snowplowTracker.disableTracking();
    }
  }, [appConfig.snowplowEnabled, snowplowTracker]);

  // Track the "app launch" Snowplow event.
  useEffect(() => {
    // The Snowplow event should be tracked on each relaunch.
    snowplowTracker.trackEvent(snowplowTracker.event.APP_OPEN);
  }, [status, initialLaunch, appConfig, snowplowTracker]);

  // Enable or disable remote logging.
  useEffect(() => {
    if (!environment.IS_DEV && userId && appConfig.remoteLoggingEnabled) {
      enableRemoteLogging(true);
    } else {
      enableRemoteLogging(false);
    }
  }, [userId, appConfig]);

  // Handle the startupMessage, potentially provided in appStart.
  // The message should only be shown if the user is logged out.
  useEffect(() => {
    if (!userId && startupMessage) {
      overlay.presentBasicAlert({
        title: startupMessage.title,
        message: startupMessage.message,
        persistent: true,
      });
    }
  }, [userId, startupMessage, overlay]);

  // Handle automatic logout on user inactivity.
  useEffect(() => {
    let countdown = appConfig.idleTimeCountdown;

    // When the user becomes idle, show an alert with a countdown.
    // If the countdown reaches zero, logout.
    if (userIsIdle && state.idleDetectionEnabled && !!userId) {
      const alertTitle = language.get('idle_alert_title');
      const alertButton = language.get('idle_alert_button');
      const alertMessageParts = language.get('idle_alert_message').split('XX');

      const counterStyle: CSSObject = {
        fontFamily: font.FAMILY_BOLD,
        color: color.ALERT,
      };

      const interval = window.setInterval(() => {
        if (countdown > 0) {
          overlay.presentAlert({
            title: alertTitle,
            messageElement: (
              <span>
                <span>{alertMessageParts[0]}</span>
                <span css={counterStyle}>{countdown}</span>
                <span>{alertMessageParts[1]}</span>
              </span>
            ),
            items: [
              {
                title: alertButton,
                onClick: () => {
                  // If the "Keep me signed in" button is clicked,
                  // just clear the countdown, as the click will be picked up as user activity.
                  clearInterval(interval);
                },
              },
            ],
          });

          countdown--;
          return;
        }

        // Countdown has reached zero. Clear the countdown and logout.
        clearInterval(interval);
        logout();
      }, 1000);
    }
  }, [
    appConfig.idleTimeCountdown,
    userIsIdle,
    userId,
    font,
    color,
    language,
    overlay,
    state.idleDetectionEnabled,
    logout,
  ]);

  // Update menu badges on WebSocket push notification.
  useEffect(() => {
    if (updateBadgeNotification) {
      const { id, textInfo } = updateBadgeNotification.payload.tabBadgeUpdate;
      updateMenuItemBadge(id, textInfo);
    }
  }, [updateBadgeNotification, updateMenuItemBadge]);

  return (
    <AppContext.Provider
      value={{
        ...state,

        updateMenuItemBadge,
        enableIdleDetection,
        reinitializeApp,
        saveSession,
        logout,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
