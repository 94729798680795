/** @jsx jsx */
import React, { useEffect, useRef } from 'react';
import { jsx } from '@emotion/core';
import {
  connect,
  ConnectOptions,
  createLocalTracks,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteVideoTrack,
  Room,
  Track,
  TwilioError,
} from 'twilio-video';
import { IMeetingTimelineEvent } from '../../../../../../contexts/meetingContext';

const TWILIO_RELEVANT_ERRORS = {
  [IMeetingTimelineEvent.CLIENT_AUTH_ERROR]: [
    // AccessTokenInvalidError
    20101,
    // AccessTokenNotYetValidError
    20105,
    // AccessTokenHeaderInvalidError
    20102,
    // AccessTokenSignatureInvalidError
    20107,
    // AccessTokenGrantsInvalidError
    20106,
    // AccessTokenIssuerInvalidError
    20103,
    // AccessTokenExpiredError
    20104,
    // AccessTokenGrantsInvalidError
    20106,
  ],
  [IMeetingTimelineEvent.CLIENT_CONNECTION_ERROR]: [
    // RoomConnectFailedError
    53104,
    // ConfigurationAcquireFailedError
    53500,
    // TrackServerTrackCapacityReachedError
    53305,
    // ConfigurationAcquireTurnFailedError
    53501,
    // RoomMaxParticipantsExceededError
    53105,
    // MediaDTLSTransportFailedError
    53407,
    // MediaConnectionError
    53405,
    // SignalingConnectionTimeoutError
    53002,
    // SignalingConnectionError
    53000,
    // SignalingConnectionDisconnectedError
    53001,
  ],
  [IMeetingTimelineEvent.CLIENT_LIFECYCLE_ERROR]: [
    // TrackInvalidError
    53300,
    // TrackNameInvalidError
    53301,
    // ParticipantIdentityInvalidError
    53200,
    // TrackNameTooLongError
    53302,
    // RoomNotFoundError
    53106,
    // TrackNameIsDuplicatedError
    53304,
    // TrackNameCharsInvalidError
    53303,
    // SignalingIncomingMessageInvalidError
    53003,
    // MediaClientRemoteDescFailedError
    53402,
    // MediaNoSupportedCodecError
    53404,
    // ParticipantMaxTracksExceededError
    53203,
    // ParticipantDuplicateIdentityError
    53205,
  ],
};

export interface ITwilioSession {
  room: string;
  token: string;
  region: ConnectOptions['region'];
}

interface ITwilio {
  session: ITwilioSession;
  disableLocalParticipantAudio: boolean;
  disableLocalParticipantVideo: boolean;
  disconnectHandler: () => void;
  errorHandler: (
    eventType?: IMeetingTimelineEvent,
    eventDetails?: string
  ) => void;
  onConnecting: () => void;
  onRemoteStreamConnected: () => void;
}

const Twilio = ({
  session,
  disableLocalParticipantAudio,
  disableLocalParticipantVideo,
  disconnectHandler,
  errorHandler,
  onConnecting,
  onRemoteStreamConnected,
  ...props
}: ITwilio) => {
  const roomRef = useRef<Room>();
  const localTracksRef = useRef<LocalTrack[]>();
  const localTrackNodeRef = useRef<HTMLDivElement>();
  const remoteTrackNodeRef = useRef<HTMLDivElement>();

  const disconnectHandlerRef = useRef(disconnectHandler);
  const errorHandlerRef = useRef(errorHandler);
  const onConnectingRef = useRef(onConnecting);
  const onRemoteStreamConnectedRef = useRef(onRemoteStreamConnected);

  const setLocalTracksContainerNode = (node: HTMLDivElement) => {
    localTrackNodeRef.current = node;
  };

  const setRemoteTracksContainerNode = (node: HTMLDivElement) => {
    remoteTrackNodeRef.current = node;
  };

  const handleError = (e: TwilioError) => {
    if (TWILIO_RELEVANT_ERRORS.CLIENT_CONNECTION_ERROR.includes(e.code)) {
      errorHandlerRef.current(
        IMeetingTimelineEvent.CLIENT_CONNECTION_ERROR,
        e.message
      );
    } else if (TWILIO_RELEVANT_ERRORS.CLIENT_LIFECYCLE_ERROR.includes(e.code)) {
      errorHandlerRef.current(
        IMeetingTimelineEvent.CLIENT_LIFECYCLE_ERROR,
        e.message
      );
    } else if (TWILIO_RELEVANT_ERRORS.CLIENT_AUTH_ERROR.includes(e.code)) {
      errorHandlerRef.current(
        IMeetingTimelineEvent.CLIENT_AUTH_ERROR,
        e.message
      );
    } else {
      // If it is a different type of error, don't sent a timeline event but log it.
      errorHandlerRef.current(undefined, `${e.code} - ${e.message}`);
    }
  };

  useEffect(() => {
    // Will be set to true if we disconnect from the room by reloading the browser
    // or manually navigating to another route.
    let forcedDisconnect = false;

    const forceDisconnectFromRoom = () => {
      forcedDisconnect = true;

      if (roomRef.current) {
        roomRef.current.disconnect();
      }

      if (localTracksRef.current) {
        localTracksRef.current.forEach((track) => {
          (track as LocalVideoTrack | LocalAudioTrack).stop();
        });
      }

      window.removeEventListener('beforeunload', forceDisconnectFromRoom);
    };

    createLocalTracks({
      audio: true,
      video: {},
    })
      .then((localTracks) => {
        localTracksRef.current = localTracks;

        localTracks.forEach((track) => {
          localTrackNodeRef.current?.appendChild(
            (track as LocalVideoTrack | LocalAudioTrack).attach()
          );
        });

        connect(session.token, {
          name: session.room,
          tracks: localTracks,
          region: session.region,
        })
          .then((room: Room) => {
            onConnectingRef.current();
            roomRef.current = room;

            const handleTrackSubscribe = (
              track: RemoteVideoTrack | RemoteAudioTrack
            ) => {
              onRemoteStreamConnectedRef.current();
              remoteTrackNodeRef.current?.appendChild(track.attach());
            };

            const handleTrackUnsubscribe = (track: Track) => {
              if (track.kind === 'data') return;
              const attachedElements = (
                track as RemoteVideoTrack | RemoteAudioTrack
              ).detach();

              attachedElements.forEach((element: HTMLMediaElement) =>
                element.remove()
              );
            };

            const setupRemoteParticipant = (participant: RemoteParticipant) => {
              participant.tracks.forEach((publication) => {
                if (!publication.isSubscribed || publication.kind === 'data')
                  return;

                remoteTrackNodeRef.current?.appendChild(
                  (
                    publication?.track as RemoteVideoTrack | RemoteAudioTrack
                  )?.attach()
                );
              });

              participant.on('trackSubscribed', handleTrackSubscribe);
              participant.on('trackUnsubscribed', handleTrackUnsubscribe);
            };

            // Attach the tracks of the room's already connected participants.
            room.participants.forEach(setupRemoteParticipant);
            room.on('participantConnected', setupRemoteParticipant);

            room.on(
              'participantDisconnected',
              (participant: RemoteParticipant) => {
                participant.tracks.forEach((publication: any) => {
                  if (!publication.isSubscribed || publication.kind === 'data')
                    return;
                  const attachedElements = publication.track.detach();
                  attachedElements.forEach((element: HTMLMediaElement) =>
                    element.remove()
                  );
                });
              }
            );

            room.on('reconnecting', (e: TwilioError) => {
              handleError(e);
            });

            room.on('trackSubscriptionFailed', (e: TwilioError) => {
              handleError(e);
            });

            room.on(
              'disconnected',
              (disconnectedRoom: Room, e: TwilioError) => {
                disconnectedRoom.localParticipant.tracks.forEach(
                  (publication: any) => {
                    publication.track.stop();
                    const attachedElements = publication.track.detach();
                    attachedElements.forEach((element: HTMLMediaElement) =>
                      element.remove()
                    );
                  }
                );
                handleError(e);
                // If we get disconnected from the room due to e.g. a browser reload or navigation,
                // do not handle the disconnect.
                if (!forcedDisconnect) {
                  disconnectHandlerRef.current();
                }
              }
            );
          })
          .catch((e: TwilioError) => {
            handleError(e);
          });
      })
      .catch((e: TwilioError) => {
        handleError(e);
      });

    // If the app is reloaded, we'll disconnect from the room,
    // bit don't want to actually act upon it.
    window.addEventListener('beforeunload', forceDisconnectFromRoom);

    return forceDisconnectFromRoom;
  }, [session]);

  // Handle user enabling/disabling the camera.
  useEffect(() => {
    if (disableLocalParticipantVideo) {
      roomRef.current?.localParticipant.videoTracks.forEach((publication) =>
        publication.track.disable()
      );
    } else {
      roomRef.current?.localParticipant.videoTracks.forEach((publication) =>
        publication.track.enable()
      );
    }
  }, [disableLocalParticipantVideo]);

  // Handle user enabling/disabling the microphone.
  useEffect(() => {
    if (disableLocalParticipantAudio) {
      roomRef.current?.localParticipant.audioTracks.forEach((publication) =>
        publication.track.disable()
      );
    } else {
      roomRef.current?.localParticipant.audioTracks.forEach((publication) =>
        publication.track.enable()
      );
    }
  }, [disableLocalParticipantAudio]);

  return (
    <div {...props}>
      <div className='twilio-remote-video' ref={setRemoteTracksContainerNode} />
      <div className='twilio-local-video' ref={setLocalTracksContainerNode} />
    </div>
  );
};

export default Twilio;
