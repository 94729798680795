import { useCallback } from 'react';
import RequestError from '../utils/requestError';
import useLanguage from './useLanguage';
import useOverlay from './useOverlay';

const useRequestError = () => {
  const language = useLanguage();
  const overlay = useOverlay();

  const handleRequestError = useCallback(
    (error?: RequestError) => {
      interface IViewLayerLError {
        code: string;
        errorTitle: string;
        errorMessage: string;
        buttonTitle: string;
      }

      // If the request was cancelled, take no action.
      if (error?.status === 20) {
        return;
      }

      const viewLayerError = (error?.body as any)?.vlError as IViewLayerLError;

      if (viewLayerError) {
        overlay.presentBasicAlert({
          title: viewLayerError.errorTitle,
          message: viewLayerError.errorMessage,
          buttonTitle: viewLayerError.buttonTitle,
        });

        return;
      }

      overlay.presentBasicAlert({
        title: language.get('error'),
        message: language.get('failed_loading_data'),
        buttonTitle: language.get('close'),
      });
    },
    [language, overlay]
  );

  return handleRequestError;
};

export default useRequestError;
