import useEventCallback from './useEventCallback';
import useRequestError from './useRequestError';

import useSnowplowTracker, {
  IStructuredSnowplowEvent,
} from './useSnowplowTracker';

import useUrlAction, { IUrlAction } from './actions/useUrlAction';
import useViewAction, { IViewAction } from './actions/useViewAction';
import useSearchAction, { ISearchAction } from './actions/useSearchAction';
import useBackAction, { IBackAction } from './actions/useBackAction';
import useRelaunchAction, {
  IRelaunchAction,
} from './actions/useRelaunchAction';
import useDismissAction, { IDismissAction } from './actions/useDismissAction';
import useSpringDetailsAction, {
  ISpringDetailsAction,
} from './actions/useSpringDetailsAction';
import useUpdateInputsAction, {
  IUpdateInputsAction,
} from './actions/useUpdateInputsAction';
import useModifyAction, { IModifyAction } from './actions/useModifyAction';
import useModifyCardAction, {
  IModifyCardAction,
} from './actions/useModifyCardAction';
import useReloadAction, { IReloadAction } from './actions/useReloadAction';
import useHttpAction, { IHttpAction } from './actions/useHttpAction';
import useChangeAction, { IChangeAction } from './actions/useChangeAction';
import useSubmitInputsAction, {
  ISubmitInputsAction,
} from './actions/useSubmitInputsAction';
import useEmptyAction, { IEmptyAction } from './actions/useEmptyAction';
import useIconAlertAction, {
  IIconAlertAction,
} from './actions/useIconAlertAction';
import useActionListAction, {
  IActionListAction,
} from './actions/useActionListAction';
import useFlexStartAction, {
  IFlexStartAction,
} from './actions/useFlexStartAction';
import useUpdateSessionAction, {
  IUpdateSessionAction,
} from './actions/useUpdateSessionAction';
import useVerifyPasswordAction, {
  IPasswordVerifyAction,
} from './actions/useVerifyPasswordAction';
import useVideoAction, { IVideoAction } from './actions/useVideoAction';
import useTabBadgeUpdateAction, {
  ITabBadgeUpdateAction,
} from './actions/useTabBadgeUpdateAction';
import useOpenPdfAction, { IOpenPdfAction } from './actions/useOpenPdfAction';
import useSSOLoginAction, {
  ISSOLoginAction,
} from './actions/useSSOLoginAction';
import useWebAuthenticationAction, {
  IWebAuthenticationAction,
} from './actions/useWebAuthenticationAction';
import useViewImageAction, {
  IViewImageAction,
} from './actions/useViewImageActions';

import useFlexNavigateAction, {
  IFlexNavigateAction,
} from './actions/useFlexNavigateAction';
import useFlexSubmitInputsAction, {
  IFlexSubmitInputsAction,
} from './actions/useFlexSubmitInputsAction';
import useFlexHttpAction, {
  IFlexHttpAction,
} from './actions/useFlexHttpAction';
import useFlexChangeAction, {
  IFlexChangeAction,
} from './actions/useFlexChangeAction';

import usePaymentRelayAction, {
  IPaymentRelayAction,
} from './actions/usePaymentRelayAction';
import usePaymentStripePayAction, {
  IPaymentStripePayAction,
} from './actions/usePaymentStripePayAction';

import useUnhandledAction from './actions/useUnhandledAction';

export interface IBaseAction {
  type: string;
  snowplowTracker?: IStructuredSnowplowEvent;
}

export type IAction =
  | IActionListAction
  | IBackAction
  | IChangeAction
  | IDismissAction
  | IEmptyAction
  | IFlexChangeAction
  | IFlexHttpAction
  | IFlexNavigateAction
  | IFlexStartAction
  | IFlexSubmitInputsAction
  | IHttpAction
  | IIconAlertAction
  | IModifyAction
  | IModifyCardAction
  | IPaymentRelayAction
  | IPaymentStripePayAction
  | IReloadAction
  | IRelaunchAction
  | ISearchAction
  | ISpringDetailsAction
  | ISubmitInputsAction
  | ITabBadgeUpdateAction
  | IUpdateInputsAction
  | IUrlAction
  | IUpdateSessionAction
  | IPasswordVerifyAction
  | IVideoAction
  | IViewAction
  | IOpenPdfAction
  | ISSOLoginAction
  | IWebAuthenticationAction
  | IViewImageAction;

const useAction = () => {
  const handleRequestError = useRequestError();

  // Screen actions
  const handleUrlAction = useUrlAction();
  const handleViewAction = useViewAction();
  const handleSearchAction = useSearchAction();
  const handleBackAction = useBackAction();
  const handleRelaunchAction = useRelaunchAction();
  const handleDismissAction = useDismissAction();
  const handleSpringDetailsAction = useSpringDetailsAction();
  const handleUpdateInputsAction = useUpdateInputsAction();
  const handleModifyAction = useModifyAction();
  const handleModifyCardAction = useModifyCardAction();
  const handleReloadAction = useReloadAction();
  const handleHttpAction = useHttpAction();
  const handleChangeAction = useChangeAction();
  const handleSubmitInputsAction = useSubmitInputsAction();
  const handleEmptyAction = useEmptyAction();
  const handleIconAlertAction = useIconAlertAction();
  const handleActionListAction = useActionListAction();
  const handleFlexStartAction = useFlexStartAction();
  const handleUpdateSessionAction = useUpdateSessionAction();
  const handleVerifyPasswordAction = useVerifyPasswordAction();
  const handleVideoAction = useVideoAction();
  const handleTabBadgeUpdateAction = useTabBadgeUpdateAction();
  const handleOpenPdfAction = useOpenPdfAction();
  const handleSSOLoginAction = useSSOLoginAction();
  const handleWebAuthenticationAction = useWebAuthenticationAction();
  const handleViewImageAction = useViewImageAction();

  // Flex actions
  const handleFlexNavigateAction = useFlexNavigateAction();
  const handleFlexSubmitInputsAction = useFlexSubmitInputsAction();
  const handleFlexHttpAction = useFlexHttpAction();
  const handleFlexChangeAction = useFlexChangeAction();

  // Payment actions
  const handlePaymentRelayAction = usePaymentRelayAction();
  const handlePaymentStripePayAction = usePaymentStripePayAction();

  // Unhandled action alert
  const handleUnhandledAction = useUnhandledAction();

  // Snowplow tracker
  const snowplowTracker = useSnowplowTracker();

  const handleAction = (action?: IAction) => {
    if (action) {
      switch (action.type) {
        case 'empty':
          handleEmptyAction();
          break;
        case 'url':
          handleUrlAction(action);
          break;
        case 'view':
          handleViewAction(action);
          break;
        case 'search':
          handleSearchAction(action);
          break;
        case 'back':
          handleBackAction();
          break;
        case 'relaunch':
          handleRelaunchAction();
          break;
        case 'dismiss':
          handleDismissAction();
          break;
        case 'reload':
          handleReloadAction();
          break;
        case 'http':
          handleHttpAction(action)
            .then((resAction) => handleAction(resAction))
            .catch((error) => handleRequestError(error));
          break;
        case 'change':
          handleChangeAction(action);
          break;
        case 'submit_inputs':
          handleSubmitInputsAction(action)
            .then((resAction) => handleAction(resAction))
            .catch((error) => handleRequestError(error));
          break;
        case 'spring_details':
          handleSpringDetailsAction(action);
          break;
        case 'update_inputs':
          handleUpdateInputsAction(action);
          break;
        case 'modify':
          handleModifyAction(action);
          break;
        case 'modify_card':
          handleModifyCardAction(action);
          break;
        case 'icon_alert':
          handleIconAlertAction(action)
            .then((resAction) => handleAction(resAction))
            .catch((error) => handleRequestError(error));
          break;
        case 'action_list':
          handleActionListAction(action)
            .then((resAction) => handleAction(resAction))
            .catch((error) => handleRequestError(error));
          break;
        case 'flex_start':
          handleFlexStartAction(action);
          break;
        case 'update_session':
          handleUpdateSessionAction(action);
          break;
        case 'verify_password':
          handleVerifyPasswordAction(action);
          break;
        case 'video':
          handleVideoAction(action);
          break;
        case 'tab_badge_update':
          handleTabBadgeUpdateAction(action);
          break;
        case 'open_pdf':
          handleOpenPdfAction(action);
          break;
        case 'sso_login':
          handleSSOLoginAction(action);
          break;
        case 'web_authentication':
          handleWebAuthenticationAction(action);
          break;
        case 'view_image':
          handleViewImageAction(action);
          break;

        // Flex actions
        case 'flex_navigate':
          handleFlexNavigateAction(action);
          break;
        case 'flex_submit_inputs':
          handleFlexSubmitInputsAction(action)
            .then((resAction) => handleAction(resAction))
            .catch((error) => handleRequestError(error));
          break;
        case 'flex_http_call':
          handleFlexHttpAction(action)
            .then((resAction) => handleAction(resAction))
            .catch((error) => handleRequestError(error));
          break;
        case 'flex_change_call':
          handleFlexChangeAction(action);
          break;

        // Payment actions
        case 'payment_relay':
          handlePaymentRelayAction(action);
          break;
        case 'payment_stripe_pi_pay':
          handlePaymentStripePayAction(action)
            .then((resAction) => handleAction(resAction))
            .catch((error) => handleRequestError(error));
          break;
        default:
          handleUnhandledAction(action);
      }

      // Snowplow tracking
      if (action.snowplowTracker) {
        snowplowTracker.trackEvent(action.snowplowTracker);
      }
    }
  };

  return useEventCallback(handleAction);
};

export default useAction;
