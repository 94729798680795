import device from '../utils/device';
import environment from '../utils/environment';

const links = {
  kry: {
    IOS: 'https://itunes.apple.com/se/app/id968052278',
    ANDROID: 'https://play.google.com/store/apps/details?id=se.kry.android',
  },

  livi: {
    IOS: 'https://itunes.apple.com/us/app/id1433906974',
    ANDROID:
      'https://play.google.com/store/apps/details?id=health.livi.android',
  },
};

const appStoreLinks = {
  IOS: links[environment.IS_KRY ? 'kry' : 'livi'].IOS,
  ANDROID: links[environment.IS_KRY ? 'kry' : 'livi'].ANDROID,
  THIS_DEVICE:
    links[environment.IS_KRY ? 'kry' : 'livi'][
      device.IS_ANDROID ? 'ANDROID' : 'IOS'
    ],
};

export default appStoreLinks;
