import { trackPromise } from 'react-promise-tracker';
import { camelizeKeys } from 'humps';
import device from './device';
import environment from './environment';
import localStorage from './localStorage';

import RequestError from './requestError';

let controller: AbortController;
let routeMapList: ISubdomainRouteMap['list'] = [];

// Session cookie lasts longer than session and can be accessed across tabs
// To persist deviceId and language settings across the tabs, localStorage is used instead of sessionStorage.
let deviceId = localStorage.get<string>('DEVICE_ID') || '';
let clientLanguage = localStorage.get<string>('CLIENT_LANGUAGE') || '';

let currentPartnerId: string;

let logoutInterceptorCallback: () => void;

export interface IRequestOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: Record<string, string>;
  credentials?: 'include' | 'omit' | 'same-origin';
  mode?: 'no-cors' | 'cors' | 'same-origin';
  body?: object;
  formData?: FormData;

  // Meta options.
  assumeJson?: boolean;
  assumeBlob?: boolean;
  silent?: boolean;
  noCamelization?: boolean;
  retryable?: boolean;
  persistent?: boolean;
}

export interface ISubdomainRouteMap {
  list: Array<{
    path: string;
    subdomain: string;
  }>;
}

export const setClientLanguageHeader = (language: string) => {
  clientLanguage = language;
  localStorage.set('CLIENT_LANGUAGE', language);
};

export const setDeviceIdHeader = (id: string) => {
  deviceId = id;
  localStorage.set('DEVICE_ID', id);
};

export const setCurrentPartnerId = (id: string) => {
  currentPartnerId = id;
};

export const setSubdomainRouteMap = (map?: ISubdomainRouteMap) => {
  routeMapList = map?.list || [];
};

export const setLogoutInterceptorCallback = (callback: () => void) => {
  logoutInterceptorCallback = callback;
};

export const cancelAllRequests = () => {
  controller.abort();
};

export const getCustomHeaders = () => {
  return {
    'X-Client-Version': '20',
    'X-Client-ID': 'kry-web',
    'X-Client-Bundle-ID': 'se.kry.web',
    'X-Client-Device-ID': deviceId,

    'X-Client-TimeZone':
      Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Stockholm',

    'X-Client-Country': environment.COUNTRY,
    'X-Client-Language': clientLanguage,

    'X-System-Version': device.ABBREVIATED_DESCRIPTION,
    'X-System-Language': navigator.language.split(/-|_/)[0],

    ...(!environment.IS_PROD && {
      'X-Client-Is-Testflight': '1',
      'X-Analytics-Debug-Enabled': '1',
    }),

    ...(currentPartnerId && {
      'X-Client-Partner': currentPartnerId,
    }),
  };
};

const DEFAULT_CREDENTIALS = 'include';
const NUMBER_OF_RETRIES = 3;

const request = <T>(url: string, options: IRequestOptions = {}): Promise<T> => {
  controller = new AbortController();

  const requestOptions: RequestInit = {
    method: options.method || 'GET',
    credentials: options.credentials || DEFAULT_CREDENTIALS,
    mode: options.mode,

    // Set custom headers, unless the credential mode is set to "omit"
    // (usually when requesting non-kry/livi services).
    ...(options.credentials !== 'omit' && {
      headers: { ...getCustomHeaders(), ...options.headers },
    }),

    // In certain cases (e.g. for Flex requests), the POST requests need to contain
    // an empty body object. However, the fetch fails if there is a body and the method is GET.
    // Make sure to only include the body in the request if the method is anything but GET.
    body:
      !options.method || options.method?.toUpperCase() === 'GET'
        ? undefined
        : options.formData ||
          (options.body ? JSON.stringify(options.body) : undefined),

    signal: options.persistent ? null : controller.signal,
  };

  // Some api urls need to be routed to a different subdomain,
  // or a different domain altogether, as in the case of France in production.
  const getApiUrlForPath = (path: string) => {
    if (environment.COUNTRY === 'FR' && environment.IS_PROD) {
      return environment.getApiUrlFrance(path);
    }

    let resultingUrl = path;
    routeMapList.forEach((match) => {
      if (url.substring(0, match.path.length) === match.path) {
        resultingUrl = environment.getApiUrlForSubdomain(match.subdomain, path);
      }
    });

    return resultingUrl;
  };

  const parseBody = (response: Response): Promise<unknown> => {
    const contentType = response.headers.get('content-type');

    if (options.assumeBlob) {
      return response.blob();
    }

    return (typeof contentType === 'string' &&
      contentType.includes('application/json')) ||
      options.assumeJson
      ? response.json().then((json) => {
          return options.noCamelization
            ? json
            : camelizeKeys(json, (key, convert) =>
                // Don't camelize keys that include uppercase chars, numbers or hyphens.
                // This way, camelization should be reversible.
                /^(?=.*?[A-Z])|(?=.*?[0-9])|(?=.*?[-])/.test(key)
                  ? key
                  : convert(key)
              );
        })
      : response.text();
  };

  const requestPromise = new Promise<T>((resolve, reject) => {
    let attempt = 0;

    const performRequest = () => {
      fetch(getApiUrlForPath(url), requestOptions)
        .then((response) => {
          parseBody(response)
            .then((body) => {
              if (response.ok) {
                resolve(body as T);
              } else {
                const message = (body as any).error || response.statusText;
                reject(
                  new RequestError(message, response.status, response, body)
                );
              }
            })
            .catch((error) => {
              // Parse error
              reject(new RequestError(error, response.status, response, {}));
            });

          // If any request returns a code in the 400-599 range, send a heartbeat to verify the session is still valid.
          // If the heartbeat request also returns a code within the same range,
          // assume the session has expired and force a logout.
          if (
            url !== '/api/user/logout' &&
            response.status >= 400 &&
            response.status < 600
          ) {
            if (url === '/api/user/heartbeat') {
              logoutInterceptorCallback();
            } else {
              request('/api/user/heartbeat', {
                method: 'PUT',
                silent: true,
                persistent: true,
              });
            }
          }
        })

        .catch((error) => {
          // If the request was cancelled, reject the promise with a mapped RequestError
          if (error.code === 20) {
            reject(new RequestError(error.message, error.code));
            return;
          }

          // If the request is set to be retryable, idle 500ms and retry.
          if (options.retryable && attempt < NUMBER_OF_RETRIES) {
            attempt++;

            setTimeout(() => {
              performRequest();
            }, 500);

            return;
          }

          reject(error);
        });
    };

    performRequest();
  });

  return options.silent ? requestPromise : trackPromise(requestPromise);
};

export default request;
