/** @jsx jsx */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { jsx } from '@emotion/core';
import request, { ISubdomainRouteMap } from '../utils/request';
import useTheme, { IClientTheme } from '../hooks/useTheme';
import { IFlexHttpCall } from '../hooks/useFlexHttpCall';
import { IRemoteImage } from '../components/RemoteImage';
import { IFlexNode } from '../views/flex/flexNode';
import { IHttpCall } from '../hooks/useHttpCall';
import { appConfigContextReducer } from '../reducers/appConfigContextReducer';
import useSnowplowTracker, {
  IStructuredSnowplowEvent,
} from '../hooks/useSnowplowTracker';
import useAdjustTracker, { IAdjustEvent } from '../hooks/useAdjustTracker';
import { ISSOLoginConfig } from './ssoLoginContext';
import { images } from '../constants/assets';
import Loader from '../components/loader';
import layout from '../constants/layout';
import parseUri from '../utils/parseUri';
import environment from '../utils/environment';

export interface IAppStartResponse {
  flexStartCall?: IFlexHttpCall;

  authorizedContent?: IAuthorizedContent;
  appConfig: IAppConfig;
  clientTheme: IClientTheme;
  universalLinks: IUniversalLinks;
  startupMessage?: IStartupMessage;
  loginViewContent: ILoginViewContent;
  topRightRemoteImage: IRemoteImage;

  inferredLanguageKey: string;
  selectedLanguage?: string;
  appLanguage: {
    [language: string]: {
      [key: string]: string;
    };
  };

  userId?: string;
  userEmail?: string;
}

export interface IAuthorizedContent {
  tabSpecification: {
    tabs: Array<{
      badge?: {
        textInfo: IMenuItemBadgeTextInfo;
        onTabViewedEndpoint: IHttpCall;
      };

      content:
        | {
            type: 'bundled';
            name: string;
          }
        | {
            type: 'flex';
            node: IFlexNode;
          };

      icon: IRemoteImage;
      id: string;
      selectedIcon: IRemoteImage;
      title: string;
      webPath: string;
      snowplowEvent?: IStructuredSnowplowEvent;
    }>;
  };
}

export type IMenuItemBadgeTextInfo =
  | {
      type: 'deferred';
      endpoint: IHttpCall;
    }
  | {
      type: 'text';
      value?: string;
    };

export interface IAppConfig {
  adjustState?: 'enabled' | 'disabled' | 'offline_mode';
  snowplowEnabled?: boolean;
  idleTimerEnabled: boolean;
  idleTime: number;
  idleTimeCountdown: number;
  meetingRoomUrl: string;
  nextAvailableTimePollingInterval?: number;
  ongoingMeetingPollInterval?: number;
  remoteLoggingEnabled?: boolean;
  subdomainRouteMap?: ISubdomainRouteMap;
  meetingVideoEndpoints: IMeetingVideoEndpoints;
  pollInterval: number;
  ssoLogin: ISSOLoginConfig;
  ssoDeeplinkHttpCall?: IHttpCall;
}

export interface INotificationCountResponse {
  count: number;
}

export interface IStartupMessage {
  title: string;
  message: string;
}

export interface ILoginViewContent {
  type: 'contextual';
  headerImage?: IRemoteImage;
  webBackgroundImage?: IRemoteImage;
  headerText: string;
  subHeaderText: string;
  buttonTitle: string;
  buttonImage?: IRemoteImage;
  disclaimerText?: string;
}

export interface IMeetingVideoEndpoints {
  getUpcoming: string;
  getOnGoing: string;
  decline: string;
  getVideoToken: string;
  reportConnection: string;
  trackTimelineEvent: string;
  hangup: string;
}

export type UniversalLinksValues =
  | {
      type: 'FLEX';
      flexConfig: IFlexHttpCall;
      requiresSession?: boolean;
      snowplowEvent?: IStructuredSnowplowEvent;
      adjustEvent?: IAdjustEvent;
    }
  | {
      type: 'SCREEN';
      httpCall: IHttpCall;
      requiresSession?: boolean;
      snowplowEvent?: IStructuredSnowplowEvent;
      adjustEvent?: IAdjustEvent;
    }
  | {
      type: 'INTERNAL';
      urlAction: string;
      requiresSession?: boolean;
      snowplowEvent?: IStructuredSnowplowEvent;
      adjustEvent?: IAdjustEvent;
    };

export interface IUniversalLinks {
  links: Record<string, UniversalLinksValues>;
  rootPaths: Array<string>;
}

export interface IAppConfigContextState extends IAppStartResponse {
  status: 'INIT' | 'REINIT' | 'OK' | 'FAILED';
  initialLaunch: boolean;
}

interface IAppConfigContext extends IAppConfigContextState {
  refreshAppStartData: () => void;
}

export const AppConfigContext = createContext({} as IAppConfigContext);

const initialState: IAppConfigContextState = {
  status: 'INIT',
  initialLaunch: true,

  // The app will not initiate before we actually have appStart data.
  // Rather than having to check for availability wherever it's used,
  // or having to mock an initial state, assert it here.
  ...({} as IAppStartResponse),
};

export const AppConfigContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(appConfigContextReducer, initialState);

  const { color } = useTheme();
  const adjustTracker = useAdjustTracker();
  const snowplowTracker = useSnowplowTracker();

  const fetchAppStartData = (initialLaunch: boolean) => {
    // @TODO: Look into handling this better.
    // A quick and dirty hack to not request api/view/start every time when running a snapshot test.
    // Instead, use a local version of the response.
    if (environment.IS_DEV && window.location.pathname === '/screen-preview') {
      request<IAppStartResponse>('/start.json').then((appStartData) => {
        dispatch({
          type: 'SET_APP_START_DATA',
          payload: {
            appStartData,
            initialLaunch,
          },
        });
      });

      return;
    }

    const deeplink = `${window.location.pathname}${window.location.search}`;
    const redirectDeeplink = parseUri(deeplink).query.get('redirect');

    request<IAppStartResponse>('/api/view/start', {
      method: 'POST',
      body: {
        deep_link_url: redirectDeeplink || deeplink,
      },
    })
      .then((appStartData) => {
        dispatch({
          type: 'SET_APP_START_DATA',
          payload: {
            appStartData,
            initialLaunch,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: 'SET_STATUS',
          payload: 'FAILED',
        });
      });
  };

  // Re-fetch the data.
  const refreshAppStartData = useCallback(() => {
    dispatch({
      type: 'SET_STATUS',
      payload: 'REINIT',
    });

    fetchAppStartData(false);
  }, []);

  // The initial fetch.
  useEffect(() => {
    fetchAppStartData(true);
  }, []);

  // Initialize the Adjust and Snowplow trackers.
  // Initialization is done here rather than in the appContext due to Adjust SDK limitations (needs to happen as early as possible).
  // Adjust is set to offline mode and wont send any data until AppConfig.adjustEnabled is available and true.
  // Events collected while Adjust is in offline mode will be sent once/if switched back to online.
  useEffect(() => {
    adjustTracker.initialize();
    adjustTracker.enableOfflineMode();

    snowplowTracker.initialize();
  }, [adjustTracker, snowplowTracker]);

  return (
    <AppConfigContext.Provider
      value={{
        ...state,
        refreshAppStartData,
      }}
    >
      {/* Show a loader while the appStart data is initially being fetched.
          Note: At this point we don't have a remote client theme and cannot use dynamic colors. */}
      <Loader
        strokeColor={color.LOCAL_INITIAL_LOADER}
        show={state.status === 'INIT'}
        css={{ zIndex: layout.BASIC_ZINDEX }}
      />

      {/* As the app completely relies on appStart data,
          do not render any children (effectively initiating the app) until it's available. */}
      {(state.status === 'OK' || state.status === 'REINIT') && children}

      {/* In case the request to appStart fails, show a simple error,
          currently just an image. */}
      {state.status === 'FAILED' && (
        <img
          src={images.ERROR_SAD_FACE}
          alt='Error'
          css={{
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            height: 175,
          }}
        />
      )}
    </AppConfigContext.Provider>
  );
};
