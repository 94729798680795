/** @jsx jsx */
import React, { useContext, Fragment } from 'react';
import { jsx, CSSObject } from '@emotion/core';

// NOTE: The Swiper css is imported from a CDN in index.html
// as we can't import it directly from the library to to autoprefixer issues.
import { Swiper, SwiperSlide } from 'swiper/react';

import { ScheduleContext } from '../../../../../contexts/scheduleContext';
import { ScreenContextProvider } from '../../../../../contexts/screenContext';
import ScheduleRow from './scheduleRow';
import Screen, { IScreen } from '../../../../screen/screen';
import Clickable from '../../../../../components/clickable';
import useTheme from '../../../../../hooks/useTheme';
import BottomSticky from '../../../../../components/bottomSticky';
import { IFlexNavigation } from '../../../../../hooks/flex/useFlexNavigation';

export interface IScheduleSlot {
  text: string;
  navigation: IFlexNavigation;
}

export interface IScheduleComponent {
  title: string;
  type: 'hour' | 'info_box';
  slots: Array<IScheduleSlot>;
}

export interface IScheduleDay {
  title: string;
  screen: IScreen;
  components: Array<IScheduleComponent>;
}

export interface ISchedule {
  title: string;
  days: Array<IScheduleDay>;
}

const Schedule = () => {
  const { color, font } = useTheme();

  const {
    schedule,
    bottomButton,
    selectedDay,

    setSelectedDay,
    setSelectedSlot,
    populateSchedule,
  } = useContext(ScheduleContext);

  if (!schedule) {
    return null;
  }

  const renderDayComponents = () => {
    if (!selectedDay) {
      return null;
    }

    if (selectedDay.screen) {
      return (
        <ScreenContextProvider routeSource={{ screen: selectedDay.screen }}>
          <Screen />
        </ScreenContextProvider>
      );
    }

    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '20px 16px 14px',
        }}
      >
        {selectedDay.components.map((component) => {
          if (component.type === 'hour') {
            return (
              <ScheduleRow
                key={`${selectedDay.title}_${component.title}`}
                slotDay={selectedDay}
                title={component.title}
                slots={component.slots}
                onSlotSelect={(newSlot) => {
                  setSelectedSlot({
                    slot: newSlot,
                    day: selectedDay,
                  });
                }}
              />
            );
          }
          return null;
        })}
      </div>
    );
  };

  const renderDays = () => {
    return (
      <Swiper
        slidesPerView='auto'
        spaceBetween={12}
        slidesOffsetBefore={20}
        slidesOffsetAfter={20}
      >
        {schedule.days.map((day, index) => {
          const selected = day.title === selectedDay?.title;

          return (
            <SwiperSlide key={index} style={{ width: 'auto' }}>
              <Clickable
                scale
                darken
                css={{
                  border: `${
                    selected ? color.PRIMARY : color.DIVIDER_LINE
                  } solid 2px`,
                  borderRadius: 29,
                  fontSize: 15,
                  backgroundColor: selected ? color.PRIMARY : color.LOCAL_WHITE,
                  color: selected ? color.LOCAL_WHITE : color.TEXT,
                  textAlign: 'center',
                  padding: '10px 19px',
                  width: 'auto',
                }}
                onClick={() => {
                  setSelectedDay(day);
                }}
                aria-pressed={selected}
              >
                {day.title}
              </Clickable>
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  const renderBottomButton = () => {
    if (bottomButton) {
      const STYLE: CSSObject = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        boxSizing: 'border-box',
        padding: 20,
        backgroundColor: color.LOCAL_WHITE,
      };

      return (
        <BottomSticky>
          <div css={STYLE}>
            <Clickable
              styleAs='button'
              onClick={() => {
                populateSchedule(bottomButton.call);
              }}
            >
              {bottomButton.title}
            </Clickable>
          </div>
        </BottomSticky>
      );
    }

    return null;
  };

  const renderHeader = () => {
    return (
      <div
        css={{
          backgroundColor: color.LOCAL_WHITE,
          borderBottom: `1px solid ${color.DIVIDER_LINE}`,
        }}
      >
        <h2
          css={{
            fontFamily: font.FAMILY_MEDIUM,
            fontSize: 12,
            padding: '30px 20px 0px',
          }}
        >
          {schedule.title}
        </h2>
        <div css={{ padding: '12px 0 20px' }}>{renderDays()}</div>
      </div>
    );
  };

  return (
    <Fragment>
      {renderHeader()}
      {renderDayComponents()}
      {renderBottomButton()}
    </Fragment>
  );
};

export default Schedule;
