/** @jsx jsx */
import React, { useState, useEffect, useContext } from 'react';
import { jsx, CSSObject } from '@emotion/core';
import { AppContext } from '../../../contexts/appContext';
import { PasswordLoginContext } from '../../../contexts/passwordLoginContext';
import Clickable from '../../../components/clickable';
import Input from '../../../components/input';
import useLanguage from '../../../hooks/useLanguage';
import Title from '../../../components/title';
import CheckBox from '../../../components/checkBox';
import request from '../../../utils/request';
import useTheme from '../../../hooks/useTheme';
import useRequestError from '../../../hooks/useRequestError';
import AttributedText from '../../../components/attributedText';
import useNavigation from '../../../hooks/useNavigation';
import apiKeys from '../../../constants/apiKeys';
import useAdjustTracker from '../../../hooks/useAdjustTracker';
import useSnowplowTracker from '../../../hooks/useSnowplowTracker';
import LocalImage from '../../../components/localImage';
import BottomSticky from '../../../components/bottomSticky';

export interface IRegisterDeviceResponse {
  sessionId: string;
  deviceId: string;
}

export interface IUserResponse {
  email: string;
  eulaAgreed: boolean;
  gdpr: boolean;
  gdprSeen: boolean;
  kryId: string;
  language: string;
  phone: string;
}

const UserDetailsView = () => {
  const { saveSession } = useContext(AppContext);
  const { deviceRegistrationForm, phone, smsCode } =
    useContext(PasswordLoginContext);

  const language = useLanguage();
  const { color } = useTheme();
  const navigation = useNavigation();
  const adjustTracker = useAdjustTracker();
  const snowplowTracker = useSnowplowTracker();
  const handleRequestError = useRequestError();

  const [inputStates, setInputStates] = useState<{
    [inputId: string]: unknown;
  }>({});

  useEffect(() => {
    if (deviceRegistrationForm && deviceRegistrationForm.userDetailsForm) {
      const defaultInputStates: { [inputId: string]: unknown } = {};
      deviceRegistrationForm.userDetailsForm.fields.forEach((item) => {
        defaultInputStates[item.id] = item.value;
      });

      setInputStates(defaultInputStates);
    }
  }, [deviceRegistrationForm]);

  if (!deviceRegistrationForm) {
    return null;
  }

  const { userDetailsForm } = deviceRegistrationForm;

  const onNextClick = async () => {
    try {
      const registerResponse = await request<IRegisterDeviceResponse>(
        '/api/view/register/device',
        {
          method: 'POST',
          body: {
            phone_number: phone.phoneNumber,
            country_code: phone.countryCode,
            code: smsCode,
            timestamp: Date.now(),
            pub_key: apiKeys.KRY_PUB,
            // Signature check is disabled in the backend for the web as web will always be treated as an untrusted client.
            // Empty string bcs its non null param.
            signature: '',
            form: {
              fields: Object.keys(inputStates).map((key) => {
                return {
                  id: key,
                  value: inputStates[key],
                  selected: false,
                };
              }),
            },
          },
        }
      );

      saveSession(registerResponse.sessionId, registerResponse.deviceId);

      // This happens before the userId is available globally as we haven't technically logged in yet at this point.
      // Get userId from /api/user and track the Adjust REGISTRATION event asn well as Snowplow USER_ACCOUNT_CREATED.
      const user = await request<IUserResponse>('/api/user', {
        retryable: true,
      });
      adjustTracker.trackEvent(adjustTracker.event.REGISTRATION, user.kryId);
      snowplowTracker.trackEvent(snowplowTracker.event.USER_ACCOUNT_CREATED);

      // After getting session and device id on successful registration,
      // we need to force user to set password, for that reason, app needs to relaunch with the received session id.
      // so backend can send down flexStartCall to set new password if user doesn't have password set.
      navigation.reinitializeApp();
    } catch (e) {
      handleRequestError(e);
    }
  };

  const updateFormData = (key: string, value: any) => {
    setInputStates((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const BUTTON_CONTAINER_STYLE: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    backgroundColor: color.LOCAL_WHITE,
  };

  const BUTTON_STYLE: CSSObject = {
    img: {
      position: 'absolute',
      width: 24,
      right: 20,
    },
  };

  return (
    <div css={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
      <Title title={language.get(userDetailsForm.title)} large />

      {/** User details fields */}
      <div css={{ marginTop: 40 }}>
        {userDetailsForm.fields.map((item, index) => {
          switch (item.type) {
            case 'text':
            case 'email':
              return (
                <Input
                  required={item.required}
                  name={item.id}
                  value={(inputStates[item.id] as string) || ''}
                  onChange={(e) => {
                    updateFormData(item.id, e.currentTarget.value);
                  }}
                  key={index}
                  type={item.type}
                  label={item.placeholder}
                  placeholder={item.placeholder}
                  css={{ marginBottom: 24 }}
                />
              );
            case 'checkbox':
              return (
                <Clickable
                  renderAs='label'
                  defaultHandler
                  darken
                  key={index}
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 -20px',
                    padding: '4px 20px',
                  }}
                >
                  <CheckBox
                    required={item.required}
                    checked={(inputStates[item.id] as boolean) || false}
                    name={item.id}
                    onChange={(e) => updateFormData(item.id, e.target.checked)}
                  />
                  <AttributedText
                    text={item.placeholder}
                    css={{ marginLeft: 6 }}
                  />
                </Clickable>
              );
            default:
              return null;
          }
        })}
      </div>

      <BottomSticky>
        <div css={BUTTON_CONTAINER_STYLE}>
          <Clickable styleAs='button' css={BUTTON_STYLE} onClick={onNextClick}>
            {language.get('next')}
            <LocalImage src='CHEVRON_RIGHT' tint='LOCAL_WHITE' alt='' />
          </Clickable>
        </div>
      </BottomSticky>
    </div>
  );
};

export default UserDetailsView;
