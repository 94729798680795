/** @jsx jsx */
import React, { Fragment, useContext } from 'react';
import { jsx } from '@emotion/core';
import Clickable from '../../../components/clickable';
import useTheme from '../../../hooks/useTheme';
import useNavigation from '../../../hooks/useNavigation';
import useLanguage from '../../../hooks/useLanguage';
import currentPartner from '../../../constants/partners';
import LocalImage from '../../../components/localImage';
import { AppConfigContext } from '../../../contexts/appConfigContext';
import layout from '../../../constants/layout';
import RemoteImage from '../../../components/RemoteImage';

const LoginViewTopSection = () => {
  const { loginViewContent, topRightRemoteImage } =
    useContext(AppConfigContext);

  const language = useLanguage();
  const navigation = useNavigation();
  const { color, font } = useTheme();

  const isPartnerBranded = !!currentPartner;

  return (
    <Fragment>
      {/* Header image - hidden in case of partnership branding */}
      {!isPartnerBranded && (
        <div
          css={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'flex-end',
            backgroundImage: `url(${loginViewContent.headerImage?.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: color.SECONDARY,
          }}
        >
          {/* Invisible div that controls the header image minimum height ratio */}
          <div
            css={{
              paddingBottom: '50%',
              [`@media (min-width: ${layout.BREAKPOINT_MEDIUM}px)`]: {
                paddingBottom: '50%',
              },
            }}
          />

          {/* Header image mask */}
          <div
            css={{
              position: 'relative',
              width: '100%',
              top: 1,
              paddingBottom: '12%',
            }}
          >
            <svg
              viewBox='0 0 100 100'
              preserveAspectRatio='none'
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                fill: color.LOCAL_WHITE,
                backgroundColor: 'transparent',
              }}
            >
              <path d='M 0 0 L 0 100 L 100 100 L 100 0 Q 50,195 0 0 Z' />
            </svg>
          </div>
        </div>
      )}

      {/* Select language button container */}
      <div
        css={{
          position: isPartnerBranded ? 'relative' : 'absolute',
          padding: '10px 10px 0',
          left: 0,
          top: 0,
        }}
      >
        <Clickable
          css={{
            display: 'flex',
            alignItems: 'center',
            width: 'auto',
            padding: '6px 6px',
            backgroundColor: color.LOCAL_WHITE,
            borderRadius: 6,
            boxShadow: layout.DEFAULT_SHADOW_MEDIUM,
          }}
          scale
          onClick={() => {
            navigation.present({
              type: 'LANGUAGE_SELECTOR',
              title: language.get('select_language'),
            });
          }}
        >
          {/* Language tag */}
          <span
            css={{
              backgroundColor: color.PRIMARY,
              color: color.LOCAL_WHITE,
              fontFamily: font.FAMILY_MEDIUM,
              fontSize: '12px',
              lineHeight: 1,
              borderRadius: 6,
              padding: '6px 9px',
            }}
          >
            {language.getCurrentLanguage().toUpperCase()}
          </span>

          {/* Chevron image */}
          <LocalImage
            src='CHEVRON_DOWN'
            tint='PRIMARY'
            css={{
              width: 24,
              marginLeft: 6,
            }}
          />
        </Clickable>
      </div>

      {/* Top right image */}
      {topRightRemoteImage && (
        <div
          css={{
            position: 'absolute',
            right: 10,
            top: 10,
            padding: 7,
            backgroundColor: color.LOCAL_WHITE,
            borderRadius: 6,
            boxShadow: layout.DEFAULT_SHADOW_MEDIUM,
          }}
        >
          <RemoteImage
            {...topRightRemoteImage}
            css={{ display: 'block', width: 96 }}
          />
        </div>
      )}

      {/* Header and subheader */}
      <div css={{ padding: '0 20px', margin: '25px 0 30px' }}>
        <h2
          css={{
            position: 'relative',
            fontSize: 32,
            fontFamily: font.FAMILY_BOLD,
            color: color.TEXT_HEADLINES,
            marginBottom: 5,
          }}
        >
          {loginViewContent.headerText}
        </h2>

        <div>{loginViewContent.subHeaderText}</div>
      </div>
    </Fragment>
  );
};

export default LoginViewTopSection;
