import platform from 'platform';

const isTouchDevice = () => {
  if ('ontouchstart' in window || window.TouchEvent) return true;

  const prefixes = ['', '-webkit-', '-moz-', '-o-', '-ms-'];
  const queries = prefixes.map((prefix) => `(${prefix}touch-enabled)`);

  return window.matchMedia(queries.join(',')).matches;
};

const isSocialMediaWebview = () => {
  const ua = navigator.userAgent || navigator.vendor;
  return (
    ua.indexOf('FBAN') > -1 ||
    ua.indexOf('FBAV') > -1 ||
    ua.indexOf('Instagram') > -1 ||
    ua.indexOf('Snapchat') > -1 ||
    ua.indexOf('Pinterest') > -1
  );
};

export const isWebRTCSupported = () => {
  const isRTCPeerConnectionSupported = () => {
    return (
      typeof window.RTCPeerConnection !== 'undefined' ||
      typeof (window as any).webkitRTCPeerConnection !== 'undefined'
    );
  };

  const isGetUserMediaSupported = () => {
    return (
      !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
      !!(window as any).getUserMedia
    );
  };

  return isGetUserMediaSupported() && isRTCPeerConnectionSupported();
};

const getAbbreviatedDeviceInfo = () => {
  // Remove spaces from the os and browser names.
  const osName = (platform.os?.family || '').split(' ').join('');
  const browserName = (platform.name || '').split(' ').join('');

  const osVersion = platform.os?.version || '';
  const browserVersion = platform.version || '';
  const majorVersion = browserVersion.substring(0, browserVersion.indexOf('.'));

  return `${osName}${osVersion}|${browserName}${majorVersion}`;
};

const device = {
  IS_IOS: platform.os?.family === 'iOS',
  IS_ANDROID: platform.os?.family === 'Android',
  IS_MOBILE: platform.os?.family === 'iOS' || platform.os?.family === 'Android',

  IS_SOCIAL_MEDIA_IN_APP_BROWSER: isSocialMediaWebview(),

  IS_TOUCH_ENABLED: isTouchDevice(),
  IS_WEB_RTC_CAPABLE: isWebRTCSupported(),

  BROWSER_NAME: platform.name,
  DESCRIPTION: platform.description,
  ABBREVIATED_DESCRIPTION: getAbbreviatedDeviceInfo(),
};

export default device;
